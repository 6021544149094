import { Dialog, DialogContent } from '@material-ui/core';
import { bool, func, string } from 'prop-types';
import React from 'react';

const ImagePreview = ({ close, on, imgUrl }) => {
	return (
		<Dialog
			open={on}
			onClose={close}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogContent>
				<img src={imgUrl} alt="Preview" />
			</DialogContent>
		</Dialog>
	);
};

ImagePreview.propTypes = {
	close: func.isRequired,
	on: bool.isRequired,
	imgUrl: string.isRequired
};

export default ImagePreview;
