// Import context
import { useMedicationsContext } from 'components/context';
import { useForm, useFormState } from 'react-final-form';

const useDeleteMedication = ({ close, index: deletedMedicationIndex }) => {
	const { change } = useForm();
	const { values } = useFormState();
	const { section } = useMedicationsContext();

	const {
		medications: { medications }
	} = values[section];

	const handleDeleteMedication = () => {
		const newMedications = medications.filter(
			(_, index) => index !== deletedMedicationIndex
		);
		change(`${section}.medications.medications`, newMedications);
		close();
	};

	const handleCancelDelete = (toggle) => () => {
		toggle();
		close();
	};

	return {
		deleteMedication: handleDeleteMedication,
		cancelDelete: handleCancelDelete
	};
};

export default useDeleteMedication;
